import React, { useState } from "react";
import { Link, useStaticQuery, query } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import './SubmitWarmupForm.css';
import SuccessText from '../SuccessText';

const ADD_SUBMISSION = gql`
  mutation AddSubmission($email: String, $warmupDescription: String) {
    addSubmission(input: {email: $email, warmupDescription: $warmupDescription}) {
      submission {
        id
      }
    }
  }
`;

const SubmitWarmupForm = () => {
  const [email, setEmail] = useState("");
  const [warmup, setWarmup] = useState("");
  const [showThanks, setShowThanks] = useState(false);

  const [addSubmission, { loading: mutationLoading, error: mutationError, data }] = useMutation(ADD_SUBMISSION, {
    onCompleted: (data) => {
      if (data.addSubmission && data.addSubmission.submission) {
        setEmail("");
        setWarmup("");
        setShowThanks(true);
      };
    }
  });

  return (
    <form onSubmit={(e) => e.preventDefault()} className="SubmitWarmupForm-container">
      <textarea rows={6} value={warmup} onChange={e => setWarmup(e.target.value)} placeholder="Explain your warm up!" style={{ marginBottom: 16 }} ></textarea>
      <input type="text" value={email} placeholder="Your Email" onChange={e => setEmail(e.target.value)} style={{ marginBottom: 16 }} />
      <button
        className="Global-secondary-submit"
        onClick={() => {
          addSubmission({variables: { email: email, warmupDescription: warmup }});
        }}
      >
        Submit
      </button>
      { showThanks ? <div style={{ marginTop: 20 }}><SuccessText text="Thank you for helping build our library!" /></div> : null }
    </form>
  )
}

export default SubmitWarmupForm;
