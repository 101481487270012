import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"
import SubmitWarmupForm from '../components/submit_warmup/SubmitWarmupForm'
import WeirdPics from '../components/splash/WeirdPics';

const SubmitWarmupPage = () => (
  <Layout>
    <div style={{ width: "100%", padding: 40, paddingBottom: 120, maxWidth: "600px", margin: "0 auto"}}>
      <SEO title="Submit your warm up" />
      <h1>Submit your warm up</h1>
      <p>Have a favorite warm up your team does? Add it to our library! Also, so we can credit you for your submission, include your name + a link to your twitter/linkedin/site in the description.</p>
      <SubmitWarmupForm />
    </div>
    <WeirdPics />
  </Layout>
)

export default SubmitWarmupPage
